
import { defineComponent } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import variables from "@/router/api";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    return {
      emailAddress: "",
      newPassword: "",
      otp: "",
      otpHeader: "",
      tokenReceived: false,
    };
  },
  setup() {
    //Create form validation object
    const forgotPassword = Yup.object().shape({
      emailAddress: Yup.string()
        .email()
        .required()
        .label("Email"),
    });

    //Create form validation object
    const resetPasswordWithOtp = Yup.object().shape({
      emailAddress: Yup.string()
        .email()
        .required()
        .label("Email address"),
      newPassword: Yup.string()
        .required()
        .min(8)
        .label("New password"),
      confirmPassword: Yup.string()
        .min(8)
        .required()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .label("Confirm Password"),
      otp: Yup.number()
        .required()
        .label("OTP"),
    });

    return {
      // onSubmitForgotPassword,
      forgotPassword,
      resetPasswordWithOtp,
    };
  },
  created() {
    document.title = "Forgot Password | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    resetPasswordToken() {
      ApiService.post(variables.RESET_PASSWORD_TOKEN, {
        emailAddress: this.emailAddress,
      })
        .then((result) => {
          if (result.error == undefined) {
            variables.toastAlert(result.data.message, "success");
            this.otpHeader = result.data.otpHeader;
            this.tokenReceived = true;
          } else {
            variables.toastAlert(result.data.error, "error");
            // alert(result.error);
          }
        })
        .catch(({ response }) => {
          variables.toastAlert(response.data.error, "error");
        });
    },

    resetPassword() {
      const data = {
        emailAddress: this.emailAddress,
        newPassword: this.newPassword,
        otp: this.otpHeader + "-" + this.otp,
      };

      ApiService.post(variables.RESET_PASSWORD, data)
        .then((result) => {
          if (result.error == undefined) {
            variables.toastAlert(result.data.message, "success");
            // alert(result.message);
            // Dummy delay
            setTimeout(() => {
              this.$router.push({ name: "sign-in" });
            }, 2000);
          } else {
            variables.toastAlert(result.data.message, "success");
            // alert(result.error);
          }
        })
        .catch(({ response }) => {
          variables.toastAlert(response.data.error, "error");
        });
    },
  },
});
